<template>
  <div>
    <h1>福祉事業部 勤怠データ取込</h1>
    <p>取込を行うCSVファイルを選択して、インポートを実行してください</p>
    <div class="ma-10">
      <FileInput v-model="file" accept=".csv" multiple text="ファイル選択" />
    </div>
    <v-divider></v-divider>
    <v-row class="ma-10">
      <v-spacer></v-spacer>
      <Btn
        color="info"
        icon="file-import"
        @click="fileImport"
        :disabled="!file || file.length < 1"
        >インポート</Btn
      >
    </v-row>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import Forms from "@/mixins/forms";
export default {
  name: "WelfareAttendanceImport",
  mixins: [Common, ShowDialogs, Forms, Api],
  components: {
    FileInput: () => import("@/components/FileInput")
  },
  computed: {},
  data() {
    return { file: [] };
  },
  methods: {
    async fileImport() {
      console.log("import", this.file);
      const params = new FormData();
      this.file.forEach(f => {
        params.append("csvfiles", f);
      });

      this.$loading();
      try {
        await this.$post(this.Paths.welfareAttendanceImport, params);
        await this.$info("データのインポートが完了しました。");
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    }
  },
  created() {
    console.log("created");
  }
};
</script>
